import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <main className="min-h-screen">
    <div className='bg-slate-300 flex items-center justify-center p-4'>
    <img className='h-48 custom-image' src="./images/logo.png" />
    </div>
    <div className='bg-slate-200 flex flex-col justify-start items-center p-4 h-screen'>
      <h3 className='text-center font-bold text-1xl'>АМБ Консалтинг</h3>
      <h3 className='text-center font-bold text-1xl'>Овластено сметководствено биро,</h3>
      <h3 className='text-center font-bold text-1xl'>Софтвер, Финансии</h3>
      <h3 className='text-center font-bold text-2xl'>Миле Алексиќ</h3>
      <h3 className='text-center font-bold text-1xl'>Овластен сметководител</h3>
      
      <dl class="max-w-md w-5/6 mt-8 text-black-300 divide-y divide-black">
       <div class="flex flex-col pb-3">
           <dt class="mb-1 text-center bold text-black-500 md:text-lg">Емаил</dt>
           <dd class="text-lg text-center font-bold font-bold">mbmile01@gmail.com</dd>
       </div>
       <div class="flex flex-col pt-3">
           <dt class="mb-1 text-center text-black-500 md:text-lg">Телефон</dt>
           <dd class="text-lg text-center font-bold">+389 70 359 710</dd>
       </div>
       <div class="flex flex-col py-3">
           <dt class="mb-1 text-center text-black-500 md:text-lg">Жиро Сметки</dt>
           <dd class="text-lg text-center font-bold">Цкб: 320 5000304635 10</dd>
           <dd class="text-lg text-center font-bold">Шпаркасе: 250 5000173606 61</dd>
       </div>
       <div class="flex flex-col py-3">
           <dt class="mb-1 text-center text-black-500 md:text-lg">АМБ Консалтинг: Жиро Сметки</dt>
           <dd class="text-lg text-center font-bold">Цкб: 320 1000311714 36</dd>
       </div>
       <div class="flex flex-col py-3">
           <dt class="mb-1 text-center text-black-500 md:text-lg">Милан МБ: Жиро Сметки</dt>
           <dd class="text-lg text-center font-bold">Цкб: 320 1000304278 34</dd>
           <dd class="text-lg text-center font-bold">Шпаркасе: 250 0701016314 21</dd>
       </div>
   </dl>

    </div>
 </main>

  );
}

export default App;
